import { defineStore } from 'pinia';
import CRCService from '@/services/Cartorio/CRCService';
import router from '@/router';

export const useCartorioStore = defineStore({
  id: 'cartorio',
  state: () => ({
    cartorio: getCartorio(),
    cartorios: getCartorios(),
    modal: null,
  }),
  getters: {
    getCns: (state) => state.cartorio?.cns,
    getNome: (state) => state.cartorio?.nome,
    getCartorios: (state) => state.cartorios,
    getModal: (state) => state.modal,
  },
  actions: {
    async setCartorio(cartorio) {
      await localStorage.setItem('cartorio', JSON.stringify(cartorio));
      this.cartorio = cartorio;
      await this.updateCartorioInSession();
    },
    async updateCartorioInSession() {
      await CRCService.setCartorioInSession(this.cartorio);
    },
    closeModal() {
      this.modal.hide();
      if (router.currentRoute.value.path === '/cartorio') router.go();
    },
    getCartorio() {
      return getCartorio();
    },
  },
});

function getCartorio() {
  return localStorage.getItem('cartorio') ? JSON.parse(localStorage.getItem('cartorio')) : null;
}

function getCartorios() {
  return localStorage.getItem('cartorios') ? JSON.parse(localStorage.getItem('cartorios')) : null;
}
