import { defineStore } from 'pinia';
import authService from '@/services/auth';
import jwt_decode from 'jwt-decode';
import Cookies from 'js-cookie';
import router from '@/router';
import { userService } from '@/services/userService';

export const useAuthStore = defineStore({
  id: 'auth',
  state: () => ({
    token: getToken(),
    user: null,
    refreshTokenTimeout: null,
  }),
  actions: {
    async login(credential) {
      if (this.token?.credentialAuthType) credential = this.token.credentialAuthType;
      const params = generateURLParams();
      window.location.href = `${
        import.meta.env.VITE_API_URL
      }/login-${credential}?${params.toString()}`;
    },
    async loginByPath() {
      const params = generateURLParams();
      let credential = 'cidadao';
      if (window.location.href.includes(`${import.meta.env.VITE_BASE_URL}/eletronica/admin`))
        credential = 'administrador';
      if (window.location.href.includes(`${import.meta.env.VITE_BASE_URL}/eletronica/cartorio`))
        credential = 'registrador';
      window.location.href = `${
        import.meta.env.VITE_API_URL
      }/login-${credential}?${params.toString()}`;
    },
    async logout() {
      window.location.href = this.urlLogout();
    },
    urlLogin() {
      const params = generateURLParams();

      return `${import.meta.env.VITE_API_URL}/login-registrador?${params.toString()}`;
    },
    urlLogout() {
      const params = new URLSearchParams({
        redirectUrl: location.protocol + '//' + location.host,
      });

      return `${import.meta.env.VITE_API_URL}/logout?${params.toString()}`;
    },
    logoutCallback() {
      this.token = null;
      this.user = null;
      Cookies.remove('application-token');
      if (['admin', 'cartorio'].includes(window.location.pathname.split('/')[0])) router.push('/');
    },
    updateToken() {
      this.token = getToken();
    },
    async verifyAndUpdateToken() {
      try {
        await authService._verifyAndUpdateToken();
        this.updateToken();
        this.startRefreshToken();
      } catch (e) {
        await this.logoutCallback();
      }
    },
    refreshToken() {
      const CONSTANT_290_SEG = 290000; // 4,5 min em mili-segundos
      const currentDate = new Date();
      const executeAt =
        this.getDecodedToken()?.last_update + CONSTANT_290_SEG - currentDate.getTime();

      if (import.meta.env.NODE_ENV !== 'production') {
        const executeTime = new Date(0);
        executeTime.setUTCMilliseconds(currentDate.getTime() + executeAt);
        console.log('currentDate', currentDate);
        console.log('executeTime', executeTime);
      }

      if (this.refreshTokenTimeout) clearTimeout(this.refreshTokenTimeout);
      this.refreshTokenTimeout = setTimeout(async () => {
        await this.verifyAndUpdateToken();
      }, executeAt);
    },
    startRefreshToken() {
      const start = Cookies.get('start-refresh-token');
      if (start && this.token) {
        this.refreshToken();
      }
    },
    getToken() {
      return getToken();
    },
    getDecodedToken() {
      return jwt_decode(getToken());
    },
    getUserData: async () => {
      useAuthStore().user = await userService.getData();
    },
  },
});

function getToken() {
  const token = Cookies.get('application-token');
  return token && JSON.parse(atob(token)).message;
}

function generateURLParams() {
  const redirectUrl = window.location.search.includes('?redirect')
    ? window.location.origin + decodeURIComponent(window.location.search.replace('?redirect=', ''))
    : window.location.href;
  return new URLSearchParams({
    redirectUrl,
  });
}
