import { defineStore } from 'pinia';

export const useNeedsConfirmationStore = defineStore({
  id: 'needsConfirmation',
  state: () => ({
    needsConfirmation: false,
  }),
  getters: {
    getNeedsConfirmation: (state) => state.needsConfirmation,
  },
  actions: {
    setNeedsConfirmation(needsConfirmation) {
      this.needsConfirmation = needsConfirmation;
    },
  },
});
