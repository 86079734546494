import { createRouter, createWebHistory } from 'vue-router';
import { useAuthStore } from '@/stores/auth';
import { useCartorioStore } from '@/stores/cartorio';
import { useNeedsConfirmationStore } from '@/stores/confirmExit';

const needConfirmation = () => useNeedsConfirmationStore().setNeedsConfirmation(true);

const isPreposto = async (to, from, next) => {
  const authType = useAuthStore().getDecodedToken().credentialAuthType;

  if (authType === 'registrador' && (!useAuthStore().user || useAuthStore().user?.isRegistrador)) {
    const cartorioStore = useCartorioStore();
    if (cartorioStore.getCns) {
      await cartorioStore.updateCartorioInSession();
    }
    return next();
  }
  next('/sem-acesso');
};

const isAuthenticated = async (to, from, next) => {
  const auth = useAuthStore();
  if (auth.token) {
    await auth.verifyAndUpdateToken();
    return next();
  } else {
    if (to.path.includes('/cidadao')) return await auth.login('cidadao');
    else if (to.path.includes('/admin')) return await auth.login('administrador');
    else if (
      to.path.includes('/cartorio') ||
      ['/', '/eletronica', '/eletronica/'].includes(to.fullPath)
    )
      return await auth.login('registrador');
  }
};

const adminRoutes = [
  {
    path: '/admin',
    name: 'isAdmin',
    component: () => import('../views/Admin/AdminView.vue'),
    beforeEnter: [isAuthenticated],
  },
];

const cartorioRoutes = [
  {
    path: '/cartorio',
    name: 'Todos os Documentos',
    component: () => import('../views/CartorioTodosOsDocumentos.vue'),
    beforeEnter: [isAuthenticated, isPreposto],
  },
  {
    path: '/cartorio/declarar-uniao-estavel',
    name: 'Declarar União Estável',
    component: () => import('../views/Cartorio/FormularioDeclararUniaoEstavel.vue'),
    beforeEnter: [isAuthenticated, isPreposto, needConfirmation],
  },
  {
    path: '/cartorio/declarar-uniao-estavel/:protocolo',
    name: 'União Estável - Dados',
    component: () => import('../views/Cartorio/FormularioDeclararUniaoEstavel.vue'),
    beforeEnter: [isAuthenticated, isPreposto, needConfirmation],
  },
  {
    path: '/cartorio/declarar-uniao-estavel/:protocolo/:path',
    name: 'União Estável - Finalização',
    component: () => import('../views/Cartorio/FormularioDeclararUniaoEstavel.vue'),
    beforeEnter: [isAuthenticated, isPreposto],
  },
  {
    path: '/',
    name: 'main',
    component: () => import('../views/MainUE/DispersorUE.vue'),
    beforeEnter: [isAuthenticated, isPreposto],
    root: true,
  },
];

const cidadaoRoutes = [
  {
    path: '/cidadao/uniao-estavel/assinar-ato/:atoHashId',
    name: 'Assinar Ato de União Estável',
    component: () => import('../views/Cidadao/AssinarAto.vue'),
    beforeEnter: [isAuthenticated],
  },
];

const authenticatedRoutes = [
  {
    path: '/minhas-solicitacoes',
    name: 'minhas-solicitacoes',
    component: () => import('../views/MinhasSolicitacoesView.vue'),
    beforeEnter: isAuthenticated,
  },
  {
    path: '/resultado-busca',
    name: 'resultado-busca',
    component: () => import('../views/ResultadoDeBusca.vue'),
    beforeEnter: isAuthenticated,
  },
  {
    path: '/processo-declarar-uniao-estavel',
    name: 'processo-declarar-uniao-estavel',
    component: () => import('../views/FormularioDeclararUniaoEstavel.vue'),
    beforeEnter: isAuthenticated,
  },
  {
    path: '/processo-habilitacao-de-casamento',
    name: 'processo-habilitacao-de-casamento',
    component: () => import('../views/ProcessoHabilitacaoDeCasamento.vue'),
    beforeEnter: isAuthenticated,
  },
  ...adminRoutes,
  ...cartorioRoutes,
  ...cidadaoRoutes,
];

const router = createRouter({
  history: createWebHistory(import.meta.env.BASE_URL),
  routes: [
    {
      path: '/termos-de-uso',
      name: 'termos-de-uso',
      component: () => import('../views/TermosDeUsoView.vue'),
    },
    {
      path: '/politica-de-privacidade',
      name: 'politica-de-privacidade',
      component: () => import('../views/PoliticaDePrivacidadeView.vue'),
    },
    {
      path: '/uniao-estavel',
      name: 'uniao-estavel',
      component: () => import('../views/UniaoEstavelView.vue'),
    },
    {
      path: '/perguntas-frequentes',
      name: 'perguntas-frequentes',
      component: () => import('../views/PerguntasFrequentesView.vue'),
    },
    {
      path: '/declarar-uniao-estavel',
      name: 'declarar-uniao-estavel',
      component: () => import('../views/DeclararUniaoEstavelView.vue'),
    },
    {
      path: '/sem-acesso',
      name: 'sem-acesso',
      root: true,
      component: () => import('../views/SemAcessoView.vue'),
    },
    {
      path: '/casamento-meio-eletronico',
      name: 'casamento-meio-eletronico',
      component: () => import('../views/CasamentoMeioEletronico.vue'),
    },
    {
      path: '/iniciar-processo',
      name: 'iniciar-processo',
      component: () => import('../views/IniciarProcesso.vue'),
    },
    {
      path: '/login',
      name: 'Login',
      component: () => import('../views/Login.vue'),
      beforeEnter: isAuthenticated,
    },
    {
      path: '/consulta-eletronica',
      name: 'consulta-eletronica',
      component: () => import('../views/ConsultaEletronica.vue'),
    },
    {
      path: '/emissao-tipo-documento',
      name: 'missao-tipo-documento',
      component: () => import('../views/EmissaoTipoDocumento.vue'),
    },
    {
      path: '/:catchAll(.*)',
      name: '404',
      component: () => import('../views/404View.vue'),
      root: true,
    },
    ...authenticatedRoutes,
  ].map((route) => {
    if (!route.root) route.path = '/eletronica' + route.path;
    return route;
  }),
});

router.beforeEach(async (to) => {
  useNeedsConfirmationStore().setNeedsConfirmation(false);
  const auth = useAuthStore();
  const { logout } = to.query;

  if (auth.token && !auth.user) await auth.getUserData();

  if (['/eletronica', '/eletronica/'].includes(to.fullPath)) {
    if (auth.token) {
      switch (auth.getDecodedToken().credentialAuthType) {
        case 'administrador':
          return '/eletronica/admin';
        case 'registrador':
          return '/eletronica/cartorio';
        default:
          break;
      }
    }
    return '/';
  }

  if (logout) {
    await auth.logoutCallback();
    return '/';
  }
});

export default router;
