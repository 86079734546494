<script setup>
import { RouterView } from 'vue-router';
import { onBeforeMount, watch } from 'vue';
import { useAuthStore } from '@/stores/auth';
import helpers from './helpers';

const { urlLogin, urlLogout } = useAuthStore();

const carregarHeaderFooter = (user) => {
  let script = document.createElement('script');

  script.setAttribute('type', 'module');
  script.innerHTML = `
        import {instalarHeaderFooter} from '${
          import.meta.env.VITE_URL_BASE_HEADER_FOOTER
        }/header-footer.min.js';
          instalarHeaderFooter({
              usuario: {
                  nome: '${user?.name || ''}',
                  cpf: '${helpers.formatarCPF(user?.cpfRegistrador || user?.cpf)}',
                  foto: '${user?.foto_de_perfil_url || ''}',
                  grupos: ${JSON.stringify(user?.groups || [])},
              },
              urlLogin: '${urlLogin()}',
              urlLogout: '${urlLogout()}',
              urlRedirecionamentoSPRC: '${urlLogin()}',
              token: '${useAuthStore().getToken()}',
          });
      `;
  document.body.appendChild(script);
};

watch(
  () => useAuthStore().user,
  (user) => {
    if (user) carregarHeaderFooter(user);
  }
);

onBeforeMount(async () => {
  carregarHeaderFooter();
});
</script>

<template>
  <div id="appContainer">
    <div id="header" style=""></div>
    <div id="body">
      <div class="w-100">
        <RouterView />
      </div>
    </div>
    <div id="footer"></div>
  </div>
</template>

<style lang="scss">
@import '@vueform/multiselect/themes/default.css';
@import url($env_url_base_header_footer + '/header-footer.css');

html,
body,
#app {
  height: 100%;
}
</style>
<style scoped lang="scss">
#appContainer {
  height: 100%;
  display: flex;
  flex-direction: column;
}

#header,
#body,
#body > div,
#footer {
  flex: 1;
}

#header,
#footer {
  display: contents;
}

#body {
  display: flex;

  > div {
    min-height: 640px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  * {
    height: 100%;
  }
}
</style>
