import { fetchWrapper } from '@/helpers/fetch-wrapper';

const baseUrl = `${import.meta.env.VITE_API_URL}/cartorio`;

const CRCService = {
  setCartorioInSession: async (cartorio) => {
    return await fetchWrapper.put(`${baseUrl}/cartorios`, { cartorio }, null);
  },
};
export default CRCService;
