import { useCartorioStore } from '@/stores/cartorio';
import { useToast } from 'vue-toastification';

const toast = useToast();

const Helper = {
  deepClone: (obj) => {
    return JSON.parse(JSON.stringify(obj));
  },
  // Retorna alterações no novo obj
  changedData: (newObj, oldObj) => {
    const keys = Object.keys(newObj);
    const result = {};

    for (const key of keys) {
      if (newObj[key] !== oldObj[key]) {
        result[key] = newObj[key];
      }
    }

    return result;
  },

  setCelularMask: (numero) => {
    if (!numero) return '';
    numero = numero.replace(/\D/g, '');

    if (numero.length > 11) {
      numero = numero.slice(numero.length - 11);
    }

    if (numero.length === 11) {
      return `(${numero.slice(0, 2)})${numero.slice(2, 7)}-${numero.slice(7)}`;
    } else {
      return numero;
    }
  },

  base64toBlob: (base64data, type) => {
    const binary = atob(base64data.split(',')[1]);
    const array = [];
    for (let i = 0; i < binary.length; i++) {
      array.push(binary.charCodeAt(i));
    }
    return new Blob([new Uint8Array(array)], { type });
  },

  openBase64InAnotherTab: (base64data, mimetype, salvar = false, fileName) => {
    const blob = Helper.base64toBlob(base64data, mimetype);
    const url = URL.createObjectURL(blob);

    if (salvar) {
      const newTab = window.open(url, '_blank');
      newTab.addEventListener('load', function () {
        const downloadLink = document.createElement('a');
        downloadLink.href = url;
        downloadLink.download = fileName;
        downloadLink.style.display = 'none';
        newTab.document.body.appendChild(downloadLink);
        downloadLink.click();
        newTab.document.body.removeChild(downloadLink);
      });
    } else {
      window.open(url);
    }
  },

  openFileInAnotherTab: (file) => {
    const type = file.type;
    const reader = new FileReader();

    if (type.includes('text')) {
      reader.onload = function (event) {
        const content = event.target.result;

        const newTab = window.open('', '_blank');
        newTab.document.write('<pre>' + content + '</pre>');
        newTab.document.close();
      };

      reader.readAsText(file);
    }

    if (type.includes('pdf')) {
      reader.onload = function ({ target: { result } }) {
        const blob = new Blob([result], { type: 'application/pdf' });
        const url = URL.createObjectURL(blob);
        window.open(url, '_blank');
      };
      reader.readAsArrayBuffer(file);
    }

    if (type.includes('image')) {
      reader.onload = function ({ target: { result } }) {
        const newTab = window.open('', '_blank');
        newTab.document.write('<img src="' + result + '" alt="Imagem">');
        newTab.document.close();
      };
      reader.readAsDataURL(file);
    }
  },

  validarCPF(cpf) {
    if (!cpf) return;
    cpf = cpf.replace(/\D/g, '');
    if (cpf.length !== 11) {
      return false;
    }
    if (/^(\d)\1+$/.test(cpf)) {
      return false;
    }
    let sum = 0;
    for (let i = 0; i < 9; i++) {
      sum += parseInt(cpf.charAt(i)) * (10 - i);
    }
    let remainder = 11 - (sum % 11);
    let digit1 = remainder === 10 || remainder === 11 ? 0 : remainder;
    sum = 0;
    for (let i = 0; i < 10; i++) {
      sum += parseInt(cpf.charAt(i)) * (11 - i);
    }
    remainder = 11 - (sum % 11);
    let digit2 = remainder === 10 || remainder === 11 ? 0 : remainder;
    return parseInt(cpf.charAt(9)) === digit1 && parseInt(cpf.charAt(10)) === digit2;
  },

  verificaCartorioConectado(cnsCadastro) {
    if (cnsCadastro !== useCartorioStore().getCartorio().cns) {
      return useCartorioStore().getCartorios.find((cartorio) => cartorio.cns === cnsCadastro).nome;
    } else {
      return false;
    }
  },

  formatarCPF(cpf) {
    if (!cpf) return '';
    const cpfLimpo = cpf.replace(/\D/g, '');
    if (cpfLimpo.length === 11) {
      return (
        cpfLimpo.substring(0, 3) +
        '.' +
        cpfLimpo.substring(3, 6) +
        '.' +
        cpfLimpo.substring(6, 9) +
        '-' +
        cpfLimpo.substring(9)
      );
    }
  },

  retornarDadosPDF: async (arquivo) => {
    const pdfWorker = await import('pdfjs-dist/build/pdf.worker');
    const pdfJS = await import('pdfjs-dist/build/pdf.min');
    pdfJS.GlobalWorkerOptions.workerSrc = pdfWorker;

    return await pdfJS.getDocument(URL.createObjectURL(arquivo)).promise;
  },

  copiarTexto(texto, mensagem) {
    navigator.clipboard
      .writeText(texto)
      .then(function () {
        if (mensagem) toast.info(mensagem);
      })
      .catch(function (err) {
        console.error('Erro ao copiar texto: ', err);
      });
  },
};

export default Helper;
